<template>
    <div ref="animationElement" class="scroll-3d-animation__inner" data-intersect>
        <div class="scroll-3d-animation__content">
            <div class="scroll-3d-animation__headline-wrap">
                <transition name="slide-fade" mode="out-in">
                    <h2 :key="currentText.key" class="scroll-3d-animation__headline">
                        <template v-if="currentText !== null && currentText.text !== null">
                            {{ currentText.text }}
                        </template>
                    </h2>
                </transition>
            </div>
        </div>
        <div class="scroll-3d-animation__media-wrap">
            <div class="scroll-3d-animation__image-wrap">
                <img ref="animationImage" class="scroll-3d-animation__animation-image" :src="defaultImageSrc" />
            </div>
        </div>
    </div>
</template>

<script>
import { scrollTop, onScroll } from '../../utils/scroll';

const animationAssetsPath = '/assets/animation';

export default {
    name: 'Scroll3dAnimation',
    props: {
        headline: {
            type: String,
            default: null
        },
        animationTexts: {
            type: Array,
            default: []
        },
        takeEveryNImages: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            ready: false,
            totalImages: 1100,
            totalFrames: 0,
            loadedImages: 0,
            scrollHeight: 0,
            elementTop: 0,
            currentText: {
                id: null,
                start: null,
                end: null,
                text: null
            }
        };
    },
    computed: {
        defaultImageSrc() {
            return `${animationAssetsPath}/anim0000.jpg`;
        }
    },
    mounted() {
        const _this = this;
        this.totalFrames = Math.floor(this.totalImages / this.takeEveryNImages);

        setTimeout(() => {
            _this.init();
        }, 1000);
    },
    methods: {
        init() {
            // Load images.
            //for (let i = 1; i <= this.totalFrames; i++) {
            for (let i = 1; i <= this.totalFrames; i++) {
                const img = new Image();
                const paddedIndex = String(i * this.takeEveryNImages).padStart(4, '0');
                img.src = `${animationAssetsPath}/anim${paddedIndex}.jpg`;

                if (img.complete) {
                    this.countImages();
                } else {
                    img.addEventListener('load', this.countImages);
                    img.addEventListener('error', this.countImages);
                }
            }
        },
        countImages(e) {
            this.loadedImages++;

            e.target.removeEventListener('load', this.countImages);
            e.target.removeEventListener('error', this.countImages);

            console.log('loaded images', this.loadedImages);
            console.log('total frames', this.totalFrames);

            //if (this.loadedImages === this.totalFrames) {
            if (this.loadedImages >= this.totalFrames) {
                console.log('All images loaded');
                this.ready = true;

                setTimeout(() => {
                    this.currentText = this.animationTexts.length > 0 ? this.animationTexts[0] : null;
                    this.elementTop = this.$refs.animationElement.getBoundingClientRect().top + scrollTop;
                    this.scrollHeight = this.$refs.animationElement.scrollHeight - window.innerHeight;
                    this.$refs.animationImage.classList.add('scroll-3d-animation__image--loaded');

                    onScroll(this.scrollHandler, true);
                }, 1);
            }
        },
        updateText(scrollFraction) {
            const newText = this.animationTexts.find(item => scrollFraction >= item.start && scrollFraction <= item.end);

            if (this.currentText !== newText && newText !== undefined) {
                this.currentText = newText;
            }
        },
        scrollHandler() {
            if (scrollTop >= this.elementTop) {
                const scrollFraction = (scrollTop - this.elementTop) / this.scrollHeight;
                const frameIndex = Math.min(Math.floor(scrollFraction * this.totalFrames), this.totalFrames - 1);
                //const paddedIndex = String(frameIndex + 1).padStart(4, '0');
                const paddedIndex = String((frameIndex + 1) *  this.takeEveryNImages).padStart(4, '0');
                this.$refs.animationImage.src = `${animationAssetsPath}/anim${paddedIndex}.jpg`;

                this.updateText(scrollFraction);
            }
        }
    }
};
</script>
