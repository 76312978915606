import { createApp } from 'vue';
import i18n from '../vue/i18n';
import StoreLocator from '../vue/components/store-locator.vue';
import Scroll3dAnimation from '../vue/components/scroll-3d-animation.vue';

export function setupVue() {

    const app = createApp();

    // We need this to make sure "white-space: pre-line" still works
    app.config.compilerOptions.whitespace = 'preserve';

    app.component('StoreLocator', StoreLocator);
    app.component('Scroll3dAnimation', Scroll3dAnimation);

    app.use(i18n);
    app.mount('#app');
}
