<template>
    <div ref="animationElement" class="scroll-3d-animation__inner" data-intersect>
        <div v-if="currentText" class="scroll-3d-animation__content-wrap">
            <transition name="slide-fade" mode="out-in">
                <div :key="currentText.key" class="scroll-3d-animation__content">
                    <h2 v-if="currentText.headline !== null" class="scroll-3d-animation__headline">
                        {{ currentText.headline }}
                    </h2>
                    <span v-if="currentText.teaser !== null" class="scroll-3d-animation__teaser">
                        {{ currentText.teaser }}
                    </span>
                </div>
            </transition>
        </div>
        <div class="scroll-3d-animation__media-wrap">
            <div class="scroll-3d-animation__image-wrap">
                <img ref="animationImage" class="scroll-3d-animation__image" :src="defaultImageSrc" />
            </div>
        </div>
    </div>
</template>

<script>
import { scrollTop, onScroll } from '../../utils/scroll';
import { breakpoints, currentWindowWidth, onWindowResize } from '../../utils/window-resize';

export default {
    name: 'Scroll3dAnimation',
    props: {
        type: {
            type: String,
            default: ''
        },
        animationTexts: {
            type: Array,
            default() {
                return [];
            }
        },
    },
    data() {
        return {
            ready: false,
            totalImages: 111,
            totalFrames: 0,
            loadedImages: 0,
            scrollHeight: 0,
            elementTop: 0,
            breakpoint: null,
            currentText: {
                id: null,
                start: null,
                end: null,
                headline: null,
                teaser: null
            }
        };
    },
    computed: {
        defaultImageSrc() {
            return currentWindowWidth < (breakpoints['sm'] - .02) ? `${this.getAssetFolders().mobile}/anim0000.jpg` : `${this.getAssetFolders().desktop}/anim0000.jpg`;
        }
    },
    mounted() {
        const _this = this;
        this.totalFrames = this.totalImages - 1; /* because the first image starts with "0" */

        setTimeout(() => {
            _this.init();
        }, 1000);

        onWindowResize(() => {
            this.elementTop = this.$refs.animationElement.getBoundingClientRect().top + scrollTop;
            this.scrollHeight = this.$refs.animationElement.scrollHeight - window.innerHeight;
            this.updateAnimation();
        });
    },
    methods: {
        init() {
            // Load images
            for (let i = 1; i <= this.totalFrames; i++) {
                const img = new Image();
                const paddedIndex = String(i).padStart(4, '0');
                img.src = currentWindowWidth < (breakpoints['sm'] - .02) ? `${this.getAssetFolders().mobile}/anim${paddedIndex}.jpg` : `${this.getAssetFolders().desktop}/anim${paddedIndex}.jpg`;

                if (img.complete) {
                    this.countImages();
                } else {
                    img.addEventListener('load', this.countImages);
                    img.addEventListener('error', this.countImages);
                }
            }
        },
        countImages(e) {
            this.loadedImages++;

            if (e && e.target) {
                e.target.removeEventListener('load', this.countImages);
                e.target.removeEventListener('error', this.countImages);
            }

            if (this.loadedImages >= this.totalFrames) {
                this.ready = true;

                setTimeout(() => {
                    this.currentText = this.animationTexts.length > 0 ? this.animationTexts[0] : null;
                    this.elementTop = this.$refs.animationElement.getBoundingClientRect().top + scrollTop;
                    this.scrollHeight = this.$refs.animationElement.scrollHeight - window.innerHeight;
                    this.$refs.animationImage.classList.add('scroll-3d-animation__image--loaded');

                    onScroll(this.scrollHandler, true);
                }, 1);
            }
        },
        updateText(scrollFraction) {
            const newText = this.animationTexts.find(item => scrollFraction >= item.start && scrollFraction <= item.end);

            if (this.currentText !== newText && newText !== undefined) {
                this.currentText = newText;
            }
        },
        updateAnimation() {
            if (scrollTop >= this.elementTop) {
                const scrollFraction = (scrollTop - this.elementTop) / this.scrollHeight;
                const frameIndex = Math.min(Math.floor(scrollFraction * this.totalFrames), this.totalFrames - 1);
                const paddedIndex = String((frameIndex + 1)).padStart(4, '0');
                this.$refs.animationImage.src = currentWindowWidth < (breakpoints['sm'] - .02) ? `${this.getAssetFolders().mobile}/anim${paddedIndex}.jpg` : `${this.getAssetFolders().desktop}/anim${paddedIndex}.jpg`;
                this.updateText(scrollFraction);
            }
        },
        scrollHandler() {
            this.updateAnimation();
        },
        getAssetFolders() {
            return {
                mobile: `/assets/animations/${this.type}/mobile`,
                desktop: `/assets/animations/${this.type}/desktop`
            };
        }
    }
};
</script>
